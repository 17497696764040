<template>
  <div class="survey-answer">
    <template v-if="isClosedAnswer">
      <h4>{{ title }}</h4>
      <survey-answer-closed :responses="allResponses" :progress="progress"></survey-answer-closed>
    </template>

    <template v-else-if="responses.length">
      <survey-answer-open v-for="response of responses" :key="response.response_id" :response="response"></survey-answer-open>
    </template>

    <template v-else>
      <p>{{ $t("survey_results.no_open_answers") }}</p>
    </template>
  </div>
</template>

<script>
import SurveyAnswerClosed from "@/web/components/external/survey/SurveyAnswerClosed";
import SurveyAnswerOpen from "@/web/components/external/survey/SurveyAnswerOpen";

const MODE_CLOSED = 0;
const MODE_OPEN = 2;

export default {
  components: {
    SurveyAnswerClosed,
    SurveyAnswerOpen
  },

  props: ["answer", "allAnswers"],

  computed: {
    title() {
      return this.answer.answer;
    },

    progress() {
      if (this.allAnswers === 0) {
        return 0;
      }

      return this.allResponses / this.allAnswers;
    },

    responses() {
      return this.answer.responses;
    },

    allResponses() {
      return this.answer.all_responses;
    },

    isClosedAnswer() {
      return this.answer.mode === MODE_CLOSED;
    }
  }
};
</script>

<style lang="scss" scoped>
.survey-answer {
  margin-bottom: 20px;
}

h4 {
  font-weight: 400;
  font-size: 18px;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}
</style>
