<template>
  <div class="spinner">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.spinner {
  display: flex;
  width: 100px;
  justify-content: space-between;
  animation: rotate 1s 0s cubic-bezier(0.7, -0.13, 0.22, 0.86) infinite;
}

.dot {
  background-color: #3264f5;
  border-radius: 100%;
  width: 16px;
  height: 16px;

  &:first-child,
  &:last-child {
    opacity: 0.8;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
