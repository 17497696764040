<template>
  <div class="survey-answer-pie-chart">
    <canvas ref="chartCanvas" width="400" height="400"></canvas>
    <div class="survey-answer-pie-chart-legend">
      <span class="legend-item" v-for="(answer, index) of answers" :key="answer.answer_id">
        <span class="legend-item-color" :style="{ backgroundColor: color(index) }"></span>
        <span class="legend-item-text">{{ answer.answer }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";

const labelPlugin = {
  drawStrokeText(ctx, text, x, y, thickness) {
    ctx.save();
    ctx.strokeStyle = "black";
    ctx.lineWidth = thickness * 2;
    ctx.lineJoin = "round";
    ctx.miterLimit = 2;
    ctx.strokeText(text, x, y);
    ctx.fillText(text, x, y);
    ctx.restore();
  },

  afterDatasetDraw(chart, options) {
    const ctx = chart.ctx;

    ctx.font = Chart.helpers.fontString("16", "normal", Chart.defaults.global.defaultFontFamily);
    ctx.textAlign = "center";
    ctx.textBaseline = "center";
    ctx.fillStyle = "#fff";

    // draw labels on pie segments
    const datasets = chart.data.datasets;
    datasets.forEach(dataset => {
      const data = dataset.data;

      data.forEach((value, index) => {
        const firstKey = Object.keys(dataset._meta)[0];
        const meta = dataset._meta[firstKey];
        const metaData = meta.data[index];

        const model = metaData._model;
        const totalResponses = meta.total;
        const midRadius = model.innerRadius + (model.outerRadius - model.innerRadius) * 0.65;
        const midAngle = model.startAngle + (model.endAngle - model.startAngle) * 0.5;

        const x = midRadius * Math.cos(midAngle);
        const y = midRadius * Math.sin(midAngle);

        const angleDiff = Math.abs(model.endAngle - model.startAngle);

        if (value != 0 && !metaData.hidden && angleDiff > Math.PI * 0.05) {
          const percent = Math.round((value / totalResponses) * 100.0);
          let displayText = `${value} (${percent}%)`;

          if (angleDiff < Math.PI * 0.05) {
            displayText = `${percent}%`;
          }

          this.drawStrokeText(ctx, displayText, model.x + x, model.y + y, 2);
        }
      });
    });
  }
};

export default {
  props: ["answers"],

  data() {
    return {
      colors: ["#FF0000", "#FF7F00", "#FFFF00", "#00FF00", "#0000FF", "#4B0082", "#8B00FF"]
    };
  },

  mounted() {
    const labels = this.answers.map(answer => answer.answer);
    const series = this.answers.map(answer => answer.all_responses);

    const ctx = this.$refs.chartCanvas;

    const datasets = [];

    const repeatColors = this.answers.map((answer, index) => this.color(index));

    this.chart = new Chart(ctx, {
      type: "pie",
      responsive: true,
      plugins: [labelPlugin],
      options: {
        legend: {
          display: false
        },
        tooltip: {
          display: false
        }
      },
      data: {
        datasets: [
          {
            data: this.series,
            backgroundColor: repeatColors
          }
        ],
        labels: this.labels
      }
    });
  },

  methods: {
    color(index) {
      return this.colors[index % this.colors.length];
    }
  },

  computed: {
    labels() {
      return this.answers.map(answer => answer.answer);
    },

    series() {
      return this.answers.map(answer => answer.all_responses);
    }

    // colors() {
    //   const mkcolor = index => `rgba(110, 0, 114, ${Math.max(1.0 - 0.08 * index, 0.30)})`;
    //   return this.answers.map(
    //     (answer, index) => mkcolor(index)
    //   );
    // }
  },

  watch: {
    answers: function() {
      this.chart.data.datasets[0].data = this.series;
      this.chart.update();
    }
  }
};
</script>

<style lang="scss">
.survey-answer-pie-chart {
  margin-bottom: 24px;
  position: relative;
}

canvas {
  margin: 0 auto 24px auto;
  max-width: 500px;
}

.legend-item {
  padding: 8px;
  display: inline-block;
}

.legend-item-color {
  margin-right: 0.5em;
  vertical-align: middle;
  display: inline-block;
  width: 1em;
  height: 1em;
}
</style>
