<template>
  <div class="session-question">
    <div class="session-question-user">
      <avatar :firstName="firstName" :lastName="lastName" :picture="picture" class="question-avatar"></avatar>
      <p>{{ fullName }}</p>
    </div>

    <p class="session-question-content">
      {{ question.question }}
    </p>
  </div>
</template>

<script>
import Avatar from '@/web/components/external/Avatar';

export default {
  components: {
    Avatar
  },

  props: ['question'],

  computed: {
    picture() {
      return this.user && this.user.picture;
    },

    fullName() {
      return this.user ? `${this.firstName} ${this.lastName}` : 'Anonymous';
    },

    firstName() {
      return this.user ? this.user.first_name : 'N';
    },

    lastName() {
      return this.user ? this.user.last_name : 'D';
    },

    user() {
      return this.question.user;
    }
  }
};
</script>

<style lang="scss" scoped>
  .session-question {
    margin: 30px 0 30px 0;
    padding-bottom: 30px;
    border-bottom: dotted 2px #f0f0f0;
    display: flex;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }

    @media (max-width: 767px) {
      margin: 20px 0 20px 0;
      flex-direction: column;
      align-items: stretch;
    }
  }

  .session-question-user {
    margin: 0 20px 0 0;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    overflow: hidden;
    overflow-wrap: break-word;
    flex-direction: column;
    align-items: center;
    flex: 0 0 90px;

    @media (max-width: 767px) {
      margin: 0 0 10px 0;
      flex-direction: row;
      flex: 1 1 auto;
    }
  }

  .avatar.question-avatar {
    margin: 0 0 10px 0;
    flex: 0 0 80px;
    width: 80px;
    height: 80px;

    @media (max-width: 767px) {
      margin: 0 10px 0 0;
      flex-basis: 48px;
      width: 48px;
      height: 48px;
    }
  }

  .session-question-content {
    padding: 1em 1.5em;
    background-color: #f3f5ff;
    border-radius: 4px;
    font-size: 20px;
    flex: 1 1;
  }

  p {
    margin: 0;
    max-width: 100%;
  }
</style>
