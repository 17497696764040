import { render, staticRenderFns } from "./ExternalPageLayout.vue?vue&type=template&id=0f40203f&scoped=true&"
import script from "./ExternalPageLayout.vue?vue&type=script&lang=js&"
export * from "./ExternalPageLayout.vue?vue&type=script&lang=js&"
import style0 from "./ExternalPageLayout.vue?vue&type=style&index=0&id=0f40203f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f40203f",
  null
  
)

export default component.exports